<template>
    <el-tabs type="demo-tabs">
        <el-tab-pane label="2023 F1在学校 世界总决赛"><news01 /></el-tab-pane>
        <el-tab-pane label="第十五届上海模型节日"><news02 /></el-tab-pane>
        <el-tab-pane label="联合国教科文组织女童和妇女教育奖"><news03 /></el-tab-pane>
    </el-tabs>
</template>

<script>
import News01 from './News01.vue'
import News02 from './News02.vue'
import News03 from './News03.vue'


export default {
    components: {
        News01,
        News02,
        News03,
    }
}
</script>