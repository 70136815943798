<template>
    <div class="service">
        <h1>进校课程设计</h1>
        
        <div class="section" v-for="(content, index) in sections" :key="index" style="white-space: break-spaces;">
            <h2 >{{ content.title }}</h2>
            <p v-if="content.body" style="text-align: left;"> &emsp; &emsp; {{ content.body }}</p>
        </div>
        
        <!-- Optional: Include images or illustrations related to the laboratory -->
        
        <!-- <div class="section" v-if="hasImages">
            <h2>图片</h2>
            <div class="flex-container">
                <div class="flex-item" v-for="(image, index) in images" :key="index">
                    <img :src="require('../assets/images/School/' + image.src)" :alt="image.alt">
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
        sections: [
            {
            title: '课程宗旨',
            body: 'STEAM科研素养培养课程中的内容覆盖五大学科：科学探索、技术实践、工程制造、艺术创造、市场营销。\n        课程模块设计按照STEAM素养要求进行设计，让学生们自己动手完成以F1在学校为核心的国际竞赛及感兴趣的相关项目，从过程中学习各种学科以及跨学科的知识，提高学生运用科学、技术、工程、数学、艺术知识创新实践能力，了解科学、技术、工程、数学、艺术交叉学科知识在实际生产生活中的应用。\n        课程设计深度依据科学教育标准开发，培养学生系统掌握科学研究方法，帮助学生理解科学研究本质。',
            },
            {
            title: '课程特色',
            body: 'STEAM课程整体具有以下四个特点：\n\n        ①  课程可延伸: 兴趣->技能->作品\n        ②  特长可发展： S.T.E.A.M. 五大领域全覆盖\n        ③  领域可转换:课程兼具STEAM，同时各有侧重\n        ④  内容全覆: 涉及五大学科',
            },
            {
            title: '课程基本目标',
            body: 'STEAM课程基本目标分为以下四个阶段：\n\n第一阶段：课程内容侧重学生的兴趣培养、让学生们觉得不是那么枯燥。\n\n第二阶段：课程内容侧重视野开拓，在学生有兴趣的基础上引导学生们知晓“某一领域目前最前沿的是什么/某一领域目前在学生身边能够摸得着的是什么”,只有视野的开拓他们才能明确有哪些方面的研究可以去做。\n\n第三阶段：随着学生对某个领域的有充分认识之后对其进行知识与基础技能训练，培养学生对工具的使用、数学的应用等。\n\n第四阶段：注重习得STEAM研究过程，培养学生规范探究的能力，促其养成独立思考的习惯。而这四个阶段的目标由于每个阶段的学生知识结构与认知能力的不同会有所侧重。',
            },
        ],
        // Images array can be added here if available
        // images: [
        //     { src: "0.jpg", alt: '图片' },
        // ],
        // hasImages: true, // Set to true if images array is defined
        };
    },
};
</script>

<style scoped>
/* Add basic styling for readability */
.service {
    padding: 6vw;
    font-family: 'FangYuan';
    color: #513890;
    font-size: calc(100vw * 20 / 1920);
    font-weight: normal;
    letter-spacing: 0.16em;
    line-height: 2em;
}

body {
line-height: 1.6;
margin: 0;
padding: 0;
}

h1 {
text-align: center;
margin-top: 3rem;
}

.section {
margin-bottom: 2rem;
}

h2 {
margin-top: 2rem;
}

ul {
display: flex;
list-style-type: disc;
margin-left: 1.5rem;
margin-bottom: 1rem;
}

ul li{
display: inline;
flex: 1;
text-align: left;
}

p {
margin-bottom: 1rem;
}

.highlight {
font-weight: bold;
}

.flex-container {
display: flex;
flex-wrap: wrap;
justify-content: space-between;
margin-top: 1rem;
}

.flex-item {
flex-basis: calc(50% - 1rem);
margin-bottom: 1rem;
}

img {
max-width: 100%;
height: auto;
}
</style>