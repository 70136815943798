<template>
    <div class="school">
        <h1>创新实验室建设</h1>
        
        <div class="section" v-for="(content, index) in sections" :key="index">
            <h2>{{ content.title }}</h2>
            <p v-if="content.body" style="text-align: left;"> &emsp; &emsp; {{ content.body }}</p>
        </div>
        
        <!-- Optional: Include images or illustrations related to the laboratory -->
        
        <div class="section" v-if="hasImages">
            <h2>实验室概览</h2>
            <div class="flex-container">
                <div class="flex-item" v-for="(image, index) in images" :key="index">
                    <img :src="require('../assets/images/School/' + image.src)" :alt="image.alt">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        sections: [
            {
            title: '实验室宗旨',
            body: '创新实验室的建设旨在为学生提供一个开放、灵活的空间，以探索和实现科技创新。实验室结合了先进的技术设备、协作工作空间和多功能研讨区，以支持从基础科学到项目化教学的各个阶段。',
            },
            {
            title: '设施与设备',
            body: '创新实验室配备了各种高科技设备和工具，如CNC数控机床、3D打印机、激光切割机、高性能计算机和各种软件平台，以及基础的电子元件和制造设备。这些资源旨在满足用户从原型设计到产品制造的需求。',
            },
            {
            title: '多学科集成',
            body: '创新实验室设计体现了促进跨学科合作的理念，将工程、计算机科学、生物科学、设计和商业学科的学生和专家聚集在一起，以便团队可以从不同角度共同解决问题。',
            },
            {
            title: '灵活的工作环境',
            body: '该实验室设计为一个灵活多变的工作环境，能够根据不同项目的需求调整空间布局。配备可移动工作站、模块化实验台和多功能会议室，便于团队协作和项目展示。',
            },
            {
            title: '教育与培训',
            body: '创新实验室还承担教育角色，为用户提供一系列工作坊、讲座和课程，内容涵盖最新的技术趋势、设计思维、产品开发流程等，旨在提升参与者的技术和创新能力。实验室不仅是技术创新的场所，也是技术教育和人才培养的基地。通过举办各种培训课程和实践活动，帮助学生和参与者掌握通用技术领域的核心知识和技能，增强他们的创新能力和实践经验。',
            },
        ],
        // Images array can be added here if available
        images: [
            { src: "0.jpg", alt: '实验室设备' },
            { src: "1.jpg", alt: '协作工作空间' },
            { src: "2.jpg", alt: '多功能会议室' },
            { src: "3.jpg", alt: '教育培训空间' },
        ],
        hasImages: true, // Set to true if images array is defined
        };
    },
};
</script>

<style scoped>
/* Add basic styling for readability */
.school {
    padding: 6vw;
    font-family: 'FangYuan';
    color: #513890;
    font-size: calc(100vw * 20 / 1920);
    font-weight: normal;
    letter-spacing: 0.16em;
    line-height: 2em;
}

body {
line-height: 1.6;
margin: 0;
padding: 0;
}

h1 {
text-align: center;
margin-top: 3rem;
}

.section {
margin-bottom: 2rem;
}

h2 {
margin-top: 2rem;
}

ul {
display: flex;
list-style-type: disc;
margin-left: 1.5rem;
margin-bottom: 1rem;
}

ul li{
display: inline;
flex: 1;
text-align: left;
}

p {
margin-bottom: 1rem;
}

.highlight {
font-weight: bold;
}

.flex-container {
display: flex;
flex-wrap: wrap;
justify-content: space-between;
margin-top: 1rem;
}

.flex-item {
flex-basis: calc(50% - 1rem);
margin-bottom: 1rem;
}

img {
max-width: 100%;
height: auto;
}
</style>