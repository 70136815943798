<template>
    <div class = "card">
        <el-row>
            <!-- <el-col :span="24"><div class="grid-content ep-bg-purple-dark" /></el-col> -->
            <!-- offset属性控制单个元素与上一个元素之间的偏移量:offset="index > 0 ? 2 : 0" -->
            <el-col
                :span="12">
                <el-card :body-style="{ padding: '0px'}" shadow="never">
                    <div class="image-container">
                        <img
                        class="image"
                        @mouseenter="zoomInImage"
                        @mouseleave="resetImageSize"
                        fit="none"
                        :src="require('../assets/images/CardVue/1.jpg')"/>
                        <div class="text-container">
                            <span style="font-family: 'ShuHei'; font-size: calc(100vw * 28 / 1920); "><h1>国际竞赛</h1></span>
                            <span>
                                <el-button text class="button" style="font-family: 'ShuHei'; font-size: calc(100vw * 20 / 1920);" @click="medalDialogVisible = true">了解详情<el-icon class="el-icon--right"><View /></el-icon></el-button>
                                <el-dialog
                                    v-model="medalDialogVisible"
                                    width="66vw"
                                    :append-to-body="true"
                                    align-center
                                    style="font-family: 'FangYuan'"
                                    destroy-on-close>
                                    <TabVue02></TabVue02>
                                    <!-- <span>Open the dialog from the center from the screen</span> -->
                                </el-dialog>
                            </span>
                        </div>
                    </div>
                </el-card>
                <el-card :body-style="{ padding: '0px'}" shadow="never">
                    <div class="image-container">
                        <img
                        class="image"
                        @mouseenter="zoomInImage"
                        @mouseleave="resetImageSize"
                        fit="none"
                        :src="require('../assets/images/CardVue/2.jpg')"/>
                        <div class="text-container">
                            <span style="font-family: 'ShuHei'; font-size: calc(100vw * 28 / 1920); "><h1>国际交流</h1></span>
                            <span>
                                <el-button text class="button" style="font-family: 'ShuHei'; font-size: calc(100vw * 20 / 1920);" @click="switchDialogVisible = true">了解详情<el-icon class="el-icon--right"><View /></el-icon></el-button>
                                <el-dialog
                                    v-model="switchDialogVisible"
                                    width="66vw"
                                    :append-to-body="true"
                                    align-center
                                    style="font-family: 'FangYuan'"
                                    destroy-on-close>
                                    <SwitchVue></SwitchVue>
                                    <!-- <span>Open the dialog from the center from the screen</span> -->
                                </el-dialog>
                            </span>
                        </div>
                    </div>
                </el-card>
                <el-card :body-style="{ padding: '0px'}" shadow="never">
                    <div class="image-container">
                        <img
                        class="image"
                        @mouseenter="zoomInImage"
                        @mouseleave="resetImageSize"
                        fit="none"
                        :src="require('../assets/images/CardVue/3.jpg')"/>
                        <div class="text-container">
                            <span style="font-family: 'ShuHei'; font-size: calc(100vw * 28 / 1920); "><h1>STEM夏令营</h1></span>
                            <span>
                                <el-button text class="button" style="font-family: 'ShuHei'; font-size: calc(100vw * 20 / 1920);" @click="campDialogVisible = true">了解详情<el-icon class="el-icon--right"><View /></el-icon></el-button>
                                <el-dialog
                                    v-model="campDialogVisible"
                                    width="66vw"
                                    :append-to-body="true"
                                    align-center
                                    style="font-family: 'FangYuan'"
                                    destroy-on-close>
                                    <CampVue></CampVue>
                                    <!-- <span>Open the dialog from the center from the screen</span> -->
                                </el-dialog>
                            </span>
                        </div>
                    </div>
                </el-card>
            </el-col>
                <el-col
                :span="12">
                <el-card :body-style="{ padding: '0px'}" shadow="never">
                    <div class="image-container">
                        <img
                        class="image"
                        @mouseenter="zoomInImage"
                        @mouseleave="resetImageSize"
                        fit="none"
                        :src="require('../assets/images/CardVue/4.jpg')"/>
                        <div class="text-container">
                            <span style="font-family: 'ShuHei'; font-size: calc(100vw * 28 / 1920); "><h1>课程设计</h1></span>
                            <span>
                                <el-button text class="button" style="font-family: 'ShuHei'; font-size: calc(100vw * 20 / 1920);" @click="serviceDialogVisible = true">了解详情<el-icon class="el-icon--right"><View /></el-icon></el-button>
                                <el-dialog
                                    v-model="serviceDialogVisible"
                                    width="66vw"
                                    :append-to-body="true"
                                    align-center
                                    style="font-family: 'FangYuan'"
                                    destroy-on-close>
                                    <ServiceVue></ServiceVue>
                                    <!-- <span>Open the dialog from the center from the screen</span> -->
                                </el-dialog>
                            </span>
                        </div>
                    </div>
                </el-card>
                <el-card :body-style="{ padding: '0px'}" shadow="never">
                    <div class="image-container">
                        <img
                        class="image"
                        @mouseenter="zoomInImage"
                        @mouseleave="resetImageSize"
                        fit="none"
                        :src="require('../assets/images/CardVue/5.jpg')"/>
                        <div class="text-container">
                            <span style="font-family: 'ShuHei'; font-size: calc(100vw * 28 / 1920); "><h1>实验室搭建</h1></span>
                            <span>
                                <el-button text class="button" style="font-family: 'ShuHei'; font-size: calc(100vw * 20 / 1920);" @click="schoolDialogVisible = true">了解详情<el-icon class="el-icon--right"><View /></el-icon></el-button>
                                <el-dialog
                                    v-model="schoolDialogVisible"
                                    width="66vw"
                                    :append-to-body="true"
                                    align-center
                                    style="font-family: 'FangYuan'"
                                    destroy-on-close>
                                    <SchoolVue></SchoolVue>
                                    <!-- <span>Open the dialog from the center from the screen</span> -->
                                </el-dialog>
                            </span>
                        </div>
                    </div>
                </el-card>
                <el-card :body-style="{ padding: '0px'}" shadow="never">
                    <div class="image-container">
                        <img
                        class="image"
                        @mouseenter="zoomInImage"
                        @mouseleave="resetImageSize"
                        fit="none"
                        :src="require('../assets/images/CardVue/6.jpg')"/>
                        <div class="text-container">
                            <span style="font-family: 'ShuHei'; font-size: calc(100vw * 28 / 1920); "><h1>赛事培训</h1></span>
                            <span>
                                <el-button text class="button" style="font-family: 'ShuHei'; font-size: calc(100vw * 20 / 1920);" @click="trainDialogVisible = true">了解详情<el-icon class="el-icon--right"><View /></el-icon></el-button>
                                <el-dialog
                                    v-model="trainDialogVisible"
                                    width="66vw"
                                    :append-to-body="true"
                                    align-center
                                    style="font-family: 'FangYuan'"
                                    destroy-on-close>
                                    <TrainVue></TrainVue>
                                    <!-- <span>Open the dialog from the center from the screen</span> -->
                                </el-dialog>
                            </span>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import SchoolVue from './SchoolVue.vue';
import ServiceVue from './ServiceVue.vue';
import SwitchVue from './SwitchVue.vue';
import TabVue01 from './TabVue01.vue';
import TabVue02 from './TabVue02.vue';
import CampVue from './CampVue.vue';
import TrainVue from './TrainVue.vue';


export default {
    data() {
        return {
            schoolDialogVisible: false,
            serviceDialogVisible: false,
            switchDialogVisible: false,
            medalDialogVisible: false,
            newsDialogVisible: false,
            campDialogVisible: false,
            trainDialogVisible: false,
            titles: [
                {text: "国际竞赛", img: "1.jpg"},
                {text: "国际交流", img: "2.jpg"},
                {text: "STEM夏令营", img: "3.jpg"},
                {text: "课程设计", img: "4.jpg"},
                {text: "实验室搭建", img: "5.jpg"},
                {text: "赛事培训", img: "6.jpg"}
            ]
        }
    },
    methods: {
        zoomInImage(event) {
            event.target.style.transform = "scale(1.2)"
        },
        resetImageSize(event) {
            event.target.style.transform = ""
        },
        // roll: function (selector) {
        //     document.getElementById(selector).scrollIntoView({
        //         behavior: "smooth",
        //         block: "start"
        //     });
        // },
    },
    components: {
        SchoolVue,
        ServiceVue,
        SwitchVue,
        TabVue01,
        TabVue02,
        CampVue,
        TrainVue
    }
}
</script>

<style lang="scss" scoped>

.el-icon {
    font-size: calc(100vw * 24 / 1920);
}

.button {
    padding: 1em;
    min-height: auto;
    color: #ffffff;
    font-weight: bold;
}

.button :hover{
    color: #513890
}

.text-container {
    position: absolute;
    top: 10vw; /* 调整与图片之间的距离 */
    left: 50%; /* 水平居中 */
    transform: translateX(-50%); /* 水平居中 */
    background-color: rgba(0, 0, 0, 0.4); /* 根据需求自定义背景色及不透明度 */
    color: #ffffff; /* 根据需求自定义文字颜色 */
    padding: 0px;
    line-height: 0px;
    height: 10vh;
    width: 50vw;
    padding: 1em;
}

.image-container {
    position: relative;
}

.image-container img {
    transition: transform 1.2s ease;
}

.image {
    width: 100%;
    display: block;
    overflow: hidden;
}

.el-card {
    height: 20vw;
}
</style>