<template>
    <div class="News01">
        <h1>2023 F1 in Schools WF赛事报道</h1>
        
        <div class="section" v-for="(content, index) in sections" :key="index" style="white-space: break-spaces;">
            <h2 >{{ content.title }}</h2>
            <video autoplay loop
                        style="width: 100%; height: 100%; object-fit: fill">
                        <source :src="require('../assets/videos/1.mp4')">
                    </video>
            <p v-if="content.body" style="text-align: left;"> &emsp; &emsp; {{ content.body }}</p>
            <span><a href="https://mp.weixin.qq.com/s/YaMl7jYmadJmCAoM41KPRg">点击了解详情</a></span>
        </div>
        
        <!-- Optional: Include images or illustrations related to the laboratory -->
        
        <!-- // <div class="section" v-if="hasImages">
        //     <h2>赛事概览</h2>
        //     <div class="flex-container">
        //         <div class="flex-item" v-for="(image, index) in images" :key="index">
        //             <img :src="require('../assets/images/FiS/' + image.src)" :alt="image.alt">
        //         </div>
        //     </div>
        // </div> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
        sections: [
            {
            title: '2023 F1 in Schools WF赛事报道',
            body: '9月8日，代表中国的四支队伍在新加坡集结，开启了2023 F1 in Schools世界赛的征程。这是时隔两年后中国队伍首次重回F1 in Schools 世界总决赛线下赛场，也是2018年后举办地再次回到新加坡，每支队伍都做了充足准备。超过26个国家 350 名学生参加了在新加坡圣淘沙名胜世界举行的世界总决赛，创下了新的世界赛纪录。',
            },
        ],
        //Images array can be added here if available
        // images: [
        //     { src: "0.jpg", alt: '' },
        //     { src: "1.jpg", alt: '' },
        //     { src: "2.jpg", alt: '' },
        //     { src: "3.jpg", alt: '' },
        //     { src: "4.jpg", alt: '' },
        //     { src: "5.jpg", alt: '' },
        //     { src: "6.jpg", alt: '' },
        //     { src: "7.jpg", alt: '' },
        //     { src: "8.jpg", alt: '' },
        //     { src: "9.jpg", alt: '' },
        // ],
        // hasImages: true, // Set to true if images array is defined
        };
    },
};
</script>

<style scoped>
/* Add basic styling for readability */
.News01 {
    padding: 6vw;
    font-family: 'FangYuan';
    color: #513890;
    font-size: calc(100vw * 20 / 1920);
    font-weight: normal;
    letter-spacing: 0.16em;
    line-height: 2em;
}

body {
line-height: 1.6;
margin: 0;
padding: 0;
}

h1 {
text-align: center;
margin-top: 3rem;
}

.section {
margin-bottom: 2rem;
}

h2 {
margin-top: 2rem;
}

ul {
display: flex;
list-style-type: disc;
margin-left: 1.5rem;
margin-bottom: 1rem;
}

ul li{
display: inline;
flex: 1;
text-align: left;
}

p {
margin-bottom: 1rem;
}

.highlight {
font-weight: bold;
}

.flex-container {
display: flex;
flex-wrap: wrap;
justify-content: space-between;
margin-top: 1rem;
}

.flex-item {
flex-basis: calc(50% - 1rem);
margin-bottom: 1rem;
}

img {
max-width: 100%;
height: auto;
}
</style>