<template>
    <div class="switch">
        <h1>国际STEM交流活动</h1>
        
        <div class="section" v-for="(content, index) in sections" :key="index" style="white-space: break-spaces;">
            <h2 >{{ content.title }}</h2>
            <p v-if="content.body" style="text-align: left;"> &emsp; &emsp; {{ content.body }}</p>
        </div>
        
        <!-- Optional: Include images or illustrations related to the laboratory -->
        
        <div class="section" v-if="hasImages">
            <h2>国际交流概览</h2>
            <div class="flex-container">
                <div class="flex-item" v-for="(image, index) in images" :key="index">
                    <img :src="require('../assets/images/Switch/' + image.src)" :alt="image.alt">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        sections: [
            {
            title: '背景与意义',
            body: '在全球化背景下，科学技术的飞速发展对各国教育体系提出了新的要求，尤其是对于科学、技术、工程、数学领域的教育———STEM教育。国际间的STEM教育交流活动作为连接世界各地学校的纽带，旨在推动教育资源的优化配置，促进知识与实践经验的跨国传播，激发学生的创新精神与实践能力，为培养未来全球化时代的科技领军人才奠定坚实基础。',
            },
            {
            title: '活动形式与内容',
            body: '国际STEM竞赛（如机器人挑战赛、编程大赛、科研项目展示等）和夏令营活动为学生提供了直接参与全球竞技、合作创新的机会。学生们在跨文化环境中学习、实践与竞争，锻炼团队协作、问题解决与沟通能力，同时也有助于发现和培养具有国际竞争力的青年才俊。',
            },
            {
            title: '成效与展望',
            body: '通过持续开展国际STEM教育交流活动，增进各校间STEM教育理念、课程内容、教学策略等方面的相互了解与借鉴。激发学生跨文化交际意识与全球视野，锻炼其在真实问题情境下的创新思维与实践操作能力。',
            },
        ],
        //Images array can be added here if available
        images: [
            { src: "0.jpg", alt: '合影' },
            { src: "1.jpg", alt: '抓拍' },
        ],
        hasImages: true, // Set to true if images array is defined
        };
    },
};
</script>

<style scoped>
/* Add basic styling for readability */
.switch {
    padding: 6vw;
    font-family: 'FangYuan';
    color: #513890;
    font-size: calc(100vw * 20 / 1920);
    font-weight: normal;
    letter-spacing: 0.16em;
    line-height: 2em;
}

body {
line-height: 1.6;
margin: 0;
padding: 0;
}

h1 {
text-align: center;
margin-top: 3rem;
}

.section {
margin-bottom: 2rem;
}

h2 {
margin-top: 2rem;
}

ul {
display: flex;
list-style-type: disc;
margin-left: 1.5rem;
margin-bottom: 1rem;
}

ul li{
display: inline;
flex: 1;
text-align: left;
}

p {
margin-bottom: 1rem;
}

.highlight {
font-weight: bold;
}

.flex-container {
display: flex;
flex-wrap: wrap;
justify-content: space-between;
margin-top: 1rem;
}

.flex-item {
flex-basis: calc(50% - 1rem);
margin-bottom: 1rem;
}

img {
max-width: 100%;
height: auto;
}
</style>