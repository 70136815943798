<template>
    <div class="AutoPlay">
        <div class="title">Highlights</div>
        <div class="subtitle">成果展示</div>
        <swiper class="swiper swiper_02"
            :modules="[Autoplay]"
            :space-between="30"
            :slides-per-view="8"
            :autoplay="{delay: 3200,
                stopOnLastSlide: false,
                disableOnInteraction: false,
                reverseDirection: false}"
            :freeMode="true"
            :loop="true"
            :speed="auto">
            <swiper-slide
                v-for="(slideContent, index) in slides">
                <img style="width: 100%; height: 100%; object-fit: fill; box-shadow: 1vw 1vw 0 0 rgba(0, 0, 255, 0.2);" :src="require('../assets/images/AutoPlay/highlights1/'+ index +'.jpg')"/>
            </swiper-slide>
        </swiper>
        <swiper class="swiper swiper_02"
            :modules="[Autoplay]"
            :space-between="30"
            :slides-per-view="8"
            :autoplay="{delay: 2800,
                stopOnLastSlide: false,
                disableOnInteraction: false,
                reverseDirection: false}"
            :free-mode="true"
            :loop="true"
            :speed="auto">
            <swiper-slide
                v-for="(slideContent, index) in slides">
                <img style="width: 100%; height: 100%; object-fit: fill; box-shadow: 1vw 1vw 0 0 rgba(0, 0, 255, 0.2);" :src="require('../assets/images/AutoPlay/highlights2/'+ index +'.jpg')"/>
            </swiper-slide>
        </swiper>
    </div>
</template>
<script>
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css/bundle';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        // Create array with 12 slides
        const slides = Array.from({ length: 12 }).map(
            (el, index) => `Slide ${index + 1}`
        );
        return {
            slides,
            Autoplay,
        };
    },
    onMounted() {
        initSwiper();
        console.log(this.slides);
    },
};
</script>

<style>
.AutoPlay .title {
    font-family: 'Regular';
    font-size: calc(100vw * 32 / 1920);
    margin-top: 3vw;
}

.AutoPlay .subtitle {
    font-family: 'FangYuan';
    font-size: calc(100vw * 28 / 1920);
    font-weight: normal;
    letter-spacing: 0.3em;
}

.swiper_02 .swiper-slide {
    width: auto !important;
    height: 15vw;
}

/* .swiper_02 .swiper-wrapper {
    -webkit-transition-timing-function: linear;
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
} */

.swiper_02 {
    margin: 3vw 0vw;
    height: 16vw;
}

.AutoPlay {
    color: #513890;
    padding: 3vw 0vw;
    /* background-image: url('../assets/images/Parallax/back1.png'); */
}
</style>