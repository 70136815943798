<template>
    <el-tabs type="demo-tabs">
        <el-tab-pane label="F1 in Schools"><F1inSchools /></el-tab-pane>
        <el-tab-pane label="FIRST机器人挑战赛"><FIRSTRobot /></el-tab-pane>
    </el-tabs>
</template>

<script>
import FIRSTRobot from "./FIRSTRobot.vue";
import F1inSchools from "./F1inSchools.vue";

export default {
    components: {
        FIRSTRobot,
        F1inSchools,
    },
}
</script>