<template>
    <div class="F1iS">
        <h1>F1在学校是什么样的比赛?</h1>
        
        <div class="section" v-for="(content, index) in sections" :key="index" style="white-space: break-spaces;">
            <h2 >{{ content.title }}</h2>
            <p v-if="content.body" style="text-align: left;"> &emsp; &emsp; {{ content.body }}</p>
        </div>
        
        <!-- Optional: Include images or illustrations related to the laboratory -->
        
        <div class="section" v-if="hasImages">
            <h2>赛事概览</h2>
            <div class="flex-container">
                <div class="flex-item" v-for="(image, index) in images" :key="index">
                    <img :src="require('../assets/images/F1iS/' + image.src)" :alt="image.alt">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        sections: [
            {
            title: 'F1在学校是什么样的比赛?',
            body: 'F1在学校STEAM挑战赛，是目前全球参与国家最多、最著名的青少年综合科技创新竞赛项目之一。自2000年源于英国，每年举办一次国际性比赛，是适合9-19岁学生参与的综合科技教育竞赛项目。目前已在50余国家的两万多所学校开展。',
            },
            {
            title: '赛事特色',
            body: '在F1 in Schools竞赛中，青少年学生团队使用工程软件和尖端制造技术来设计、构建微型F1赛车并一决高下。为了取得成功，团队需要仿真工具来完成赛车的设计、测试和优化工作。\n        F1 in Schools始终致力于提供振奋人心、富有挑战性的教育体验，借助一级方程式比赛的吸引力来提高人们对STEM的认识，并为来自世界各地的学生创造难忘的体验。',
            },
            {
            title: '赛事内容',
            body: 'F1在学校是一项跨学科综合科技创新挑战赛，内容包括科学、技术、工程、数学、设计和营销管理，团队在现实生活中模仿F1赛事，设计、测试、制造和竞赛一辆小型的F1赛车，并为此提供营销、赞助和品牌推广计划。\n        一支F1在学校队伍由3-6名学生组成，分为车队经理、市场营销经理、三维工程师、设计工程师、平面设计师、制造工程师六个角色，同学们各司其职，相互协作，共同完成。',
            },
        ],
        //Images array can be added here if available
        images: [
            { src: "0.jpg", alt: '' },
            { src: "1.jpg", alt: '' },
            { src: "2.jpg", alt: '' },
            { src: "3.jpg", alt: '' },
            { src: "4.jpg", alt: '' },
            { src: "5.jpg", alt: '' },
            { src: "6.jpg", alt: '' },
            { src: "7.jpg", alt: '' },
            { src: "8.jpg", alt: '' },
            { src: "9.jpg", alt: '' },
        ],
        hasImages: true, // Set to true if images array is defined
        };
    },
};
</script>

<style scoped>
/* Add basic styling for readability */
.F1iS {
    padding: 6vw;
    font-family: 'FangYuan';
    color: #513890;
    font-size: calc(100vw * 20 / 1920);
    font-weight: normal;
    letter-spacing: 0.16em;
    line-height: 2em;
}

body {
line-height: 1.6;
margin: 0;
padding: 0;
}

h1 {
text-align: center;
margin-top: 3rem;
}

.section {
margin-bottom: 2rem;
}

h2 {
margin-top: 2rem;
}

ul {
display: flex;
list-style-type: disc;
margin-left: 1.5rem;
margin-bottom: 1rem;
}

ul li{
display: inline;
flex: 1;
text-align: left;
}

p {
margin-bottom: 1rem;
}

.highlight {
font-weight: bold;
}

.flex-container {
display: flex;
flex-wrap: wrap;
justify-content: space-between;
margin-top: 1rem;
}

.flex-item {
flex-basis: calc(50% - 1rem);
margin-bottom: 1rem;
}

img {
max-width: 100%;
height: auto;
}
</style>