<template>
    <div class="FRC">
        <h1>FIRST机器人挑战赛</h1>
        
        <div class="section" v-for="(content, index) in sections" :key="index" style="white-space: break-spaces;">
            <h2 >{{ content.title }}</h2>
            <p v-if="content.body" style="text-align: left;"> &emsp; &emsp; {{ content.body }}</p>
        </div>
        
        <!-- Optional: Include images or illustrations related to the laboratory -->
        
        <div class="section" v-if="hasImages">
            <h2>赛事概览</h2>
            <div class="flex-container">
                <div class="flex-item" v-for="(image, index) in images" :key="index">
                    <img :src="require('../assets/images/FRC/' + image.src)" :alt="image.alt">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        sections: [
            {
            title: 'FRC是什么样的比赛?',
            body: 'FIRST  Robotics Competition (FRC) 是由美国非盈利机构FIRST主办，针对高中生、大专生的一项工业级机器人竞赛。 FRC把运动的刺激性和科学技术的精确结合在一起，被称作“智力上的运动会”。该项赛事已经获得全球100多所高校的认可，如美国麻省理工大学、加拿大的多伦多大学、澳大利亚的麦考瑞大学等。\n        FRC在全球有3000多家赞助商，其中不乏国际知名企业，包括全球赛事启动赞助商 美国国家宇航局NASA、Google、AndyMark、Microsoft等。',
            },
            {
            title: '赛事特色',
            body: 'FRC比赛截止到2017年已经在美国举办了26届；全球共队伍2000多支。每年4月，FRC世锦赛都会在美国圣路易斯举行，届时，有来自中国、美国、英国、澳大利亚、巴西、加拿大、智利 、德国等队伍参加比赛。\n        在参加区域选拔赛上，全球各地的FRC队伍都可以参加任意地区的区域选拔赛；所有队伍在比赛当天检录完毕后，系统会自动生成资格赛的联盟对阵表并自动生成排名表和淘汰赛的联盟对阵表。这就意味着：所有的队伍既是盟友，也是对手，队伍间有激烈的对抗，也需相互合作，这种竞合的历练，正是学生步入社会前所需要的锻炼。\n        参与FRC，学生能够体验非常贴近现实生活的工程学，激发对于科技的兴趣。',
            },
            {
            title: '赛事内容',
            body: 'FRC是一个团队赛事，一般每个团队有10人以上高中生组队，一个队伍中包含多个岗位职责，如队长、工程师、程序员、运营、宣传等，作为团队中的一员，如何在团队中发挥自己的特长，与其他队友沟通协作，都能够锻炼参赛者的综合能力。参赛过程中涉及到编写程序、软件开发、机械设计、模型制作、生产加工等内容，参赛者也可以根据自己的喜好，选择想要了解的岗位亲身体验，可以在过程中发现自己的潜在能力。',
            },
        ],
        //Images array can be added here if available
        images: [
            { src: "0.jpg", alt: '' },
            { src: "1.jpg", alt: '' },
            { src: "2.jpg", alt: '' },
            { src: "3.jpg", alt: '' },
        ],
        hasImages: true, // Set to true if images array is defined
        };
    },
};
</script>

<style scoped>
/* Add basic styling for readability */
.FRC {
    padding: 6vw;
    font-family: 'FangYuan';
    color: #513890;
    font-size: calc(100vw * 20 / 1920);
    font-weight: normal;
    letter-spacing: 0.16em;
    line-height: 2em;
}

body {
line-height: 1.6;
margin: 0;
padding: 0;
}

h1 {
text-align: center;
margin-top: 3rem;
}

.section {
margin-bottom: 2rem;
}

h2 {
margin-top: 2rem;
}

ul {
display: flex;
list-style-type: disc;
margin-left: 1.5rem;
margin-bottom: 1rem;
}

ul li{
display: inline;
flex: 1;
text-align: left;
}

p {
margin-bottom: 1rem;
}

.highlight {
font-weight: bold;
}

.flex-container {
display: flex;
flex-wrap: wrap;
justify-content: space-between;
margin-top: 1rem;
}

.flex-item {
flex-basis: calc(50% - 1rem);
margin-bottom: 1rem;
}

img {
max-width: 100%;
height: auto;
}
</style>