<template>
    <div class="News03">
        <h1>快讯</h1>
        
        <div class="section" v-for="(content, index) in sections" :key="index" style="white-space: break-spaces;">
            <h2 >{{ content.title }}</h2>
            <p v-if="content.body" style="text-align: left;"> &emsp; &emsp; {{ content.body }}</p>
        </div>
        
        <!-- Optional: Include images or illustrations related to the laboratory -->
        
        <div class="section" v-if="hasImages">
            <h2>概览</h2>
            <div class="flex-container">
                <div class="flex-item" v-for="(image, index) in images" :key="index">
                    <img :src="require('../assets/images/News03/' + image.src)" :alt="image.alt">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        sections: [
            {
            title: 'F1 in Schools项目闪耀联合国教科文组织女童妇女教育奖颁奖典礼',
            body: '北京——在2023年联合国教科文组织女童和妇女教育奖颁奖仪式上，备受瞩目的F1 in Schools教育项目展示其魅力，受到与会人员关注。此次活动特别之处在于，中国国家主席习近平夫人、同时也是联合国教科文组织促进女童和妇女教育特使的彭丽媛女士，与联合国教科文组织总干事奥黛丽·阿祖莱共同参与了一场别开生面的发车仪式。\n        此次发车仪式不仅象征着对科技教育领域性别平等的重视，也展现了F1 in Schools项目对提升女童和妇女在科学、技术、工程及数学（STEM）领域教育机会的承诺。彭丽媛和阿祖莱通过与现场学生的亲切交流，进一步强调了教育对于实现性别平等和赋能女性的重要性。\n        F1 in Schools是一项全球性的青少年科技教育项目，旨在通过赛车设计与制作的竞赛，激发学生们对于STEM领域的兴趣。项目鼓励学生们运用创意、团队合作以及沟通技巧，解决实际问题。',
            },
            {
            title: '活动意义',
            body: '通过F1 in Schools等项目，可以有效提高女性在科技领域的参与度和影响力，为她们打开通往科技世界的大门。教育是实现性别平等的关键，而科技教育对于女性的赋能尤为重要。我们期待在不久的将来，性别平等和女性在STEM领域的全面参与将成为现实。',
            },
        ],
        //Images array can be added here if available
        images: [
            { src: "0.jpg", alt: '' },
            { src: "1.jpg", alt: '' },
        ],
        hasImages: true, // Set to true if images array is defined
        };
    },
};
</script>

<style scoped>
/* Add basic styling for readability */
.News03 {
    padding: 6vw;
    font-family: 'FangYuan';
    color: #513890;
    font-size: calc(100vw * 20 / 1920);
    font-weight: normal;
    letter-spacing: 0.16em;
    line-height: 2em;
}

body {
line-height: 1.6;
margin: 0;
padding: 0;
}

h1 {
text-align: center;
margin-top: 3rem;
}

.section {
margin-bottom: 2rem;
}

h2 {
margin-top: 2rem;
}

ul {
display: flex;
list-style-type: disc;
margin-left: 1.5rem;
margin-bottom: 1rem;
}

ul li{
display: inline;
flex: 1;
text-align: left;
}

p {
margin-bottom: 1rem;
}

.highlight {
font-weight: bold;
}

.flex-container {
display: flex;
flex-wrap: wrap;
justify-content: space-between;
margin-top: 1rem;
}

.flex-item {
flex-basis: calc(50% - 1rem);
margin-bottom: 1rem;
}

img {
max-width: 100%;
height: auto;
}
</style>