<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <NavMenu></NavMenu>
    <carousel id="news"></carousel>
    <Parallax id="about"></Parallax>
    <card id="services"></card>
    <AutoPlay id="highlights"></AutoPlay>
  </div>
  
  <div class="footer">
    <p><a href="https://beian.miit.gov.cn" target="_blank" rel="noopener">沪ICP备2024060180号-1</a><br>违法和不良信息举报：abuse@s7edu.com</p>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';
import AutoPlay from '@/components/AutoPlay.vue';
import card from '@/components/CardVue.vue';
import carousel from '@/components/Carousel.vue';
import NavMenu from '@/components/NavMenu.vue';
import Parallax from '@/components/Parallax.vue';

export default {
  name: 'HomeView',
  components: {
    // HelloWorld,
    NavMenu,
    carousel,
    Parallax,
    card,
    AutoPlay,
  }
}
</script>

<style>
.footer {
  margin-top: 3vw;
  padding: 3vw;
  background-color: rgba(0, 0, 255, 0.2);
  color: #513890;
  text-align: center;
  font-family: 'ShuHei';
  font-size: calc(100vw * 20 / 1920)
}
</style>
